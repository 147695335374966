import permissions from "../constants/permissions";
import PolicyModuleLayout from "@/views/Pages/PolicyModule/PolicyModuleLayout.vue";
import PolicyModuleMenu from "@/views/Pages/PolicyModule/PolicyModuleMenu.vue";

// import middleware
import authenticated from "@/middleware/authenticated";
import authorized from "@/middleware/authorized";

// import pages
const PolicyPage = () =>
    import("@/views/Pages/PolicyModule/PolicyManagement/ListPolicyPage.vue");

let policyManagementPages = {
    path: "policies",
    components: {
        default: PolicyModuleLayout,
        SidebarSecondLevelMenu: PolicyModuleMenu,
    },
    children: [
        {
            path: "",
            name: "List Policies",
            components: { default: PolicyPage },
            meta: {
                middleware: authorized,
                permission: permissions.PERM_VIEW_ANY_POLICIES,
                hasLevel2: true,
            },
        },
    ],
};

const policyModuleRoutes = [
    {
        path: "",
        components: {
            default: PolicyModuleLayout,
            SidebarSecondLevelMenu: PolicyModuleMenu,
        },
        children: [
            {
                path: "",
                name: "Policy Page",
                components: { default: PolicyPage },
                meta: {
                    middleware: authenticated,
                    hasLevel2: true,
                },
            },
        ],
    },
    policyManagementPages
];

export default policyModuleRoutes;
