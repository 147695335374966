export default {
  POLICIES_LIST: "Liste des politiques",
  ADD_POLICY: "Ajouter une politique",
  POLICY_DETAILS: "Détails politique",
  POLICY_ADDED: "Politique ajouté",
  EDIT_POLICY: "Modifier une politique",
  POLICY_UPDATED: "Politique mis à jour",
  DELETE_THIS_POLICY: "Voulez-vous supprimer cette politique sélectionné ?",
  DELETE_POLICY_SUCCESS: "La suppression de la politique a été effectuée avec succès",
  POLICY_DELETED: "Politique supprimé",
  NO_DATA: "Vous n'avez ajouté aucun client pour le moment, cliquer sur le bouton pour ajouter.",
  POLICY_NUMBER: "N°",
  POLICY_NAME: "Nom",
  POLICY_TYPE: "Sélection le type de politique",
  POLICY_TYPE_DETAILS: "Type de la politique",
  POLICY_DESCRIPTION: "Description",
  POLICY_CREATED_AT: "Crée le",
  POLICY_MODIFIED_AT: "Modifié le",
  POLICY_GENERAL_INFORMATION: "Générale",
  POLICY_INFORMATION_GENERAL: "Information générale",
  POLICY_PAYMENT: "Politiques de paiement",
  POLICY_PAYMENT_TYPE_LABEL: "Type",
  POLICY_PAYMENT_TYPE: "Sélectionner 1 parmi les options suivantes",
  POLICY_PAYMENT_TYPE_AFTER_CREATE: "Après la création de la réservation",
  POLICY_PAYMENT_TYPE_AFTER_CANCEL: "Après l'annulation de la réservation",
  POLICY_PAYMENT_TYPE_AFTER_ARRIVE: "Après l'arrivée de la réservation",
  POLICY_PAYMENT_TYPE_BEFORE_ARRIVE: "Avant et jusqu'à l'arrivée de la réservation",
  POLICY_PAYMENT_TYPE_BEFORE_DEPARTURE: "Avant le départ de la réservation",
  PASTE: "Coller",
  POLICY_STATUS: "Statut de la politique",
  STATUS: "Statut",
  POLICY_SELECT: "Sélectionner dans la liste",
  POLICY_ACTIVE: "Actif",
  POLICY_INACTIVE: "Inactif",
  POLICY_SELECT_GUARANTEE: "Sélectionner une ou plusieurs politiques de garanties",
  POLICY_PAYMENTS: "Politique de paiement",
  POLICY_CANCELLATION: "Politique d'annulation",
  POLICY_GUARANTEE: "Politique de garantie",
  POLICY_GUARANTEE_LABEL: "Sélectionner un ou plusieurs politiques de garanties",
  POLICY_HOURS: "Nombre d'heures",
  HOURS: "heures",
  POLICY_AUTOMATIQUE: "Automatique",
  PAYMENT: "Paiement",
  PAYMENT_TYPE: "Sélection",
  PAYMENT_TYPE_PERCENT: "Pourcentage",
  PAYMENT_TYPE_DOLLAR: "Dollar",
  PAYMENT_TYPE_NIGHT: "Nuit(s)",
  PAYMENT_TYPE_PERCENT_TITLE: "Pourcentage à charger",
  PAYMENT_TYPE_DOLLAR_TITLE: "Montant à charger",
  PAYMENT_TYPE_NIGHT_TITLE: "Nombre de nuits à charger",
  CANCELLATION_TITLE: "Frais d'annulation",
  COMMUNICATION: "Communication",
  SUCCESS: "En cas de succès",
  ERROR: "En cas d'échec",
  SELECT_EMAIL: "Sélectionner un courriel",
  SELECT_EMAIL_PLACEHOLDER: "Sélectionner un courriel dans la liste",
  NO_SEND_EMAIL: "Ne pas envoyer de email",
  SEND_EMAIL: "Envoyer un email au client",
  NO_SEND_EMAIL_CAMPING: "Ne pas envoyer de email au camping",
  SEND_EMAIL_CAMPING: "Envoyer un email au camping",
  SEND_EMAIL_NB: "NB: Pour vous informer que le camping que les frais d'annulation n'ont pu être appliqués",
  ADD: "Ajouter",
  CANCEL: "Annuler",
  COPY_FAILURE: "Échec de la copie des informations.",
  INVALID_PASTE_FORMAT: "Échec du collage des informations. Format non valide.",
  ERROR_FETCHING_DATA_IN_CLIPBOARD: "Échec de la récupération des informations depuis le presse-papiers.",
  CREATE_MODEL: "Créer un nouveau modèle",
};
