import service from "@/store/services/policies-service";

export const state = () => ({
  list: {},
  spot: {},
  meta: {},
  url: null,
  unavailableDates : [],
});

export const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, policy) => {
    state.policy = policy;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_URL: (state, url) => {
    state.url = url;
  },
  SET_UNAVAILABLE_DATES: (state, dates) => {
    state.unavailableDates = dates;
  },
};

export const actions = {

  list({ commit, dispatch }, params = {}) {
    return service.list(params, this.$axios).then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },

  get({ commit, dispatch }, { id, params = {} }) {
    return service.get({ id, params }, this.$axios).then((policy) => {
                    commit("SET_RESOURCE", policy);
                  });
  },

  add({ commit, dispatch }, params) {
    return service.add(params, this.$axios).then((policy) => {
      commit("SET_RESOURCE", policy);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params, this.$axios).then((policy) => {
      commit("SET_RESOURCE", policy);
    });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params, this.$axios);
  },

  clean({ commit, dispatch }, params) {
    return service.clean(params, this.$axios).then((policy) => {
      commit("SET_RESOURCE", policy);
    });
  },

};

const getters = {
  list: (state) => state.list,
  listTotal: (state) => state.meta.page.total,
  policy: (state) => state.policy,
  url: (state) => state.url,
  unavailableDates: (state) => state.unavailableDates,
  dropdown: (state) => {
    return state.list.map((policy) => ({
                        id: policy.id,
                        name: policy.name,
                      }));
  },
};

const policies = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default policies;
