import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };
  return axios.get(`${url}/policies`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

/**
 * Récupérer une emplacement avec sont id.
 *
 * @param params  '{id: 'identitiant'}'
 * @returns {Promise<TJsonaModel | Array<TJsonaModel>>}
 */
function get({ id, params = {} }) {
  params.include = "organization";

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    }
  };

  return axios
    .get(
      `${url}/policies/${id}`,
      options
    )
    .then((response) => {
      let policy = jsona.deserialize(response.data);
      delete policy?.links;
      return policy;
    });
}

function add(policy) {
  const payload = jsona.serialize({
    stuff: policy,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.post(`${url}/policies`, payload, options).then((response) => {
    return jsona.deserialize(response.data);
  });
}

function update(policy) {
  const payload = jsona.serialize({
    stuff: policy,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(`${url}/policies/${policy.id}`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/policies/${id}`, options);
}

function clean(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(
      `${url}/policies/${id}/clean?include=organization,createdBy`,
      {},
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

export default {
  list,
  get,
  add,
  update,
  destroy,
  clean,
};
